import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import { InvoicesDetailsPage, InvoicesListPage } from './pages'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/invoices/:id" Component={InvoicesDetailsPage} />
        <Route path="/invoices" Component={InvoicesListPage} />
        <Route path="*" element={<Navigate to="/invoices" />} />
      </Routes>
    </Router>
  )
}

export default App
