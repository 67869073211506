import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'

interface BadgeWithTooltipProps {
  tooltipText: string
  placement?: 'top' | 'right' | 'bottom' | 'left'
  bg?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
  children: React.ReactNode
}

export const BadgeWithTooltip = ({
  children,
  tooltipText,
  placement = 'bottom',
  bg = 'info',
}: BadgeWithTooltipProps) => (
  <OverlayTrigger
    overlay={<Tooltip>{tooltipText}</Tooltip>}
    placement={placement}
  >
    <Badge bg={bg}>{children}</Badge>
  </OverlayTrigger>
)
