import { toNumber } from 'lodash'
import { useState } from 'react'
import { Button, Form, Offcanvas } from 'react-bootstrap'
import { Product } from 'types'
import ProductAutocomplete from '../../../components/ProductAutocomplete'

interface AddProductOverlayProps {
  show: boolean
  handleClose: () => void
  onAddProduct: (product: Product, quantity: number) => void
}

export const AddProductOverlay = ({
  show,
  handleClose,
  onAddProduct,
}: AddProductOverlayProps) => {
  const [product, setProduct] = useState<Product | null>(null)
  const [quantity, setQuantity] = useState(1)

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      backdrop={true}
      scroll={false}
      style={{
        width: '33.33%',
        maxWidth: '33.33%',
      }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Products</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ProductAutocomplete value={product} onChange={setProduct} />
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter quantity"
              min={1}
              max={100}
              value={quantity}
              onChange={(e) => setQuantity(toNumber(e.target.value))}
            />
          </Form.Group>
        </Form>
        <Button
          variant="primary"
          disabled={!product}
          onClick={() => {
            if (product) {
              onAddProduct(product, quantity)
            }
          }}
        >
          Add Product
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
