import React from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { Invoice } from 'types'

interface InvoiceLinesProps {
  invoice: Invoice
  canEditInvoiceLines: boolean
  onDeleteLine: (lineId: number) => void
  onAddLine: () => void
}

const InvoiceLines: React.FC<InvoiceLinesProps> = ({
  invoice,
  canEditInvoiceLines,
  onDeleteLine,
  onAddLine,
}) => {
  const getPrice = (price: string) => {
    return Number(price).toFixed(2)
  }

  if (!invoice?.invoice_lines || invoice.invoice_lines.length === 0) {
    return canEditInvoiceLines ? (
      <Card>
        <Card.Body className="text-center">
          <Button onClick={onAddLine} className="btn btn-primary">
            Add Products
          </Button>
        </Card.Body>
      </Card>
    ) : null
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>Invoice Lines</Card.Title>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Description</th>
              <th className="text-end">Unit Price</th>
              <th>Unit</th>
              <th>Position Price</th>
              {canEditInvoiceLines && <th></th>}
            </tr>
          </thead>
          <tbody>
            {invoice.invoice_lines.map((line) => (
              <tr key={line.id}>
                <td>
                  {line.quantity}x {line.label}
                </td>
                <td className="text-end">
                  ${getPrice(line.product.unit_price)}
                </td>
                <td>{line.unit}</td>
                <td>${line.price}</td>
                {canEditInvoiceLines && (
                  <td>
                    <Button
                      className="btn btn-danger"
                      onClick={() => onDeleteLine(line.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        {invoice.total && (
          <Card.Text className="text-end">
            <strong>Total: ${getPrice(invoice.total)}</strong>, incl. $
            {invoice.tax} tax
          </Card.Text>
        )}
      </Card.Body>
      {canEditInvoiceLines && (
        <Card.Footer>
          <Button className="btn btn-primary" onClick={onAddLine}>
            Add Line
          </Button>
        </Card.Footer>
      )}
    </Card>
  )
}

export default InvoiceLines
