import React from 'react'
import { Card } from 'react-bootstrap'
import { Invoice } from 'types'

interface CustomerInfoCardProps {
  invoice: Invoice
}

export const CustomerInfoCard: React.FC<CustomerInfoCardProps> = ({
  invoice,
}) => {
  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Customer</Card.Title>
          <Card.Text>
            {invoice?.customer?.first_name} {invoice?.customer?.last_name}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Address</Card.Title>
          <Card.Text>
            {invoice?.customer?.address}, {invoice?.customer?.zip_code}{' '}
            {invoice?.customer?.city}
            {invoice?.customer?.country && `, ${invoice?.customer?.country}`}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}
