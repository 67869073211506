import { useApi } from 'api'
import { toNumber } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Invoice, Product } from 'types'

export const useInvoiceOperations = () => {
  const api = useApi()
  const navigate = useNavigate()

  const addProduct = async (
    invoice: Invoice,
    product: Product,
    quantity: number
  ) => {
    try {
      await api.putInvoice(
        { id: invoice.id },
        {
          invoice: {
            ...invoice,
            customer_id: invoice.customer_id ?? 0,
            invoice_lines_attributes: [
              {
                product_id: product.id,
                quantity: quantity,
                unit: product.unit,
                label: product.label,
                price: (toNumber(product.unit_price) * quantity).toString(),
                tax: (
                  toNumber(product.unit_price) *
                  quantity *
                  (toNumber(product.vat_rate) / 100)
                ).toString(),
                vat_rate: product.vat_rate,
              },
            ],
          },
        }
      )
      return true
    } catch (error) {
      console.error("Failed to update invoice's invoice lines", error)
      return false
    }
  }

  const finalizeInvoice = async (invoice: Invoice) => {
    if (!invoice) return false

    try {
      await api.putInvoice(
        { id: invoice.id },
        {
          invoice: {
            ...invoice,
            finalized: true,
            date: new Date().toISOString(),
            deadline: new Date(
              Date.now() + 30 * 24 * 60 * 60 * 1000
            ).toISOString(),
            customer_id: invoice.customer_id ?? 0,
          },
        }
      )
      return true
    } catch (error) {
      console.error('Failed to finalize invoice', error)
      return false
    }
  }

  const markInvoiceAsPaid = async (invoice: Invoice) => {
    if (!invoice) return false

    try {
      await api.putInvoice(
        { id: invoice.id },
        {
          invoice: {
            ...invoice,
            paid: true,
            customer_id: invoice.customer_id ?? 0,
          },
        }
      )
      return true
    } catch (error) {
      console.error('Failed to mark invoice as paid', error)
      return false
    }
  }

  const bulkMarkInvoicesAsPaid = async (invoices: Invoice[]) => {
    try {
      await Promise.all(invoices.map((invoice) => markInvoiceAsPaid(invoice)))
      return true
    } catch (error) {
      console.error('Failed to mark invoices as paid', error)
      return false
    }
  }

  const deleteInvoice = async (invoice: Invoice) => {
    try {
      await api.deleteInvoice({ id: invoice.id })
      navigate('/invoices')
      return true
    } catch (error) {
      console.error('Failed to delete invoice', error)
      return false
    }
  }

  const deleteInvoiceLine = async (invoice: Invoice, lineId: number) => {
    try {
      await api.putInvoice(
        { id: invoice.id },
        {
          invoice: {
            ...invoice,
            invoice_lines_attributes: invoice.invoice_lines?.map((line) => {
              if (line.id === lineId) {
                return { ...line, _destroy: true }
              }
              return line
            }),
            customer_id: invoice.customer_id ?? 0,
          },
        }
      )
      return true
    } catch (error) {
      console.error('Failed to delete invoice line', error)
      return false
    }
  }

  return {
    addProduct,
    finalizeInvoice,
    markInvoiceAsPaid,
    bulkMarkInvoicesAsPaid,
    deleteInvoice,
    deleteInvoiceLine,
  }
}
