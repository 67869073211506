import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

interface OverdueInvoicesAlertProps {
  overdueCount: number
}

export const OverdueInvoicesAlert: React.FC<OverdueInvoicesAlertProps> = ({
  overdueCount,
}) => {
  const navigate = useNavigate()

  if (overdueCount === 0) {
    return null
  }

  const handleViewOverdueInvoices = () => {
    navigate('/invoices?overdue=true')
  }

  return (
    <Alert
      variant="warning"
      className="d-flex justify-content-between align-items-center"
    >
      <div>
        <strong>Overdue Alert!</strong> You have {overdueCount} overdue invoice
        {overdueCount !== 1 ? 's' : ''} that need your attention.
      </div>
      <Button variant="success" onClick={handleViewOverdueInvoices}>
        View Overdue Invoices
      </Button>
    </Alert>
  )
}
