import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApiProvider } from '../api'
import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'

const domRoot = document.getElementById('root')
const root = createRoot(domRoot!)

const apiUrl = process.env.REACT_APP_API_URL
let apiToken = process.env.REACT_APP_API_TOKEN

if (!apiUrl) {
  console.error('Configuration Error: REACT_APP_API_URL is missing.')
  console.log('Please add the following to your .env file:')
  console.log('REACT_APP_API_URL=your_api_url_here')
  throw new Error('REACT_APP_API_URL is not set')
}

if (!apiToken) {
  console.log(
    'No API token found in environment variables. Checking localStorage...'
  )
  apiToken = localStorage.getItem('API_TOKEN') ?? undefined

  if (!apiToken) {
    console.error('Configuration Error: API Token is missing.')
    console.log('For testing, you can set the token persistently:')
    console.log('localStorage.setItem("API_TOKEN", "your_test_token_here")')
    console.log('Then reload the page.')
  }
}

root.render(
  <React.StrictMode>
    {!apiToken ? (
      <div className="alert alert-warning">
        No API token provided. Please use localStorage.setItem('API_TOKEN',
        'your_token_here') to set the token and refresh the page.
      </div>
    ) : (
      <ApiProvider url={apiUrl} token={apiToken}>
        <App />
      </ApiProvider>
    )}
  </React.StrictMode>
)
