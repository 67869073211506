import { useApi } from 'api'
import { InvoicesHeader } from 'app/components/InvoicesHeader'
import { useInvoiceOperations } from 'app/hooks/use-invoice-operations'
import { useCallback, useEffect, useState } from 'react'
import { Button, Container, Table } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Invoice } from 'types'
import { OverdueInvoicesAlert } from './components/OverdueInvoicesAlert'

const InvoicesListPage = (): React.ReactElement => {
  const api = useApi()
  const { bulkMarkInvoicesAsPaid } = useInvoiceOperations()

  const [invoicesList, setInvoicesList] = useState<Invoice[]>([])
  const [overdueInvoicesList, setOverdueInvoicesList] = useState<Invoice[]>([])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [isFetching, setIsFetching] = useState(false)

  const isOverdueFilterActive = searchParams.get('overdue') === 'true'

  const invoicesToDisplay = isOverdueFilterActive
    ? overdueInvoicesList
    : invoicesList

  const getInvoiceStatus = (invoice: Invoice) => {
    if (invoice.paid) {
      return 'Paid'
    } else if (
      invoice.finalized &&
      !invoice.paid &&
      invoice.deadline &&
      new Date(invoice.deadline) < new Date()
    ) {
      return 'Overdue'
    } else if (invoice.finalized) {
      return 'Sent'
    } else {
      return 'Draft'
    }
  }

  const fetchInvoices = useCallback(async () => {
    setIsFetching(true)
    const [invoicesResponse, overdueResponse] = await Promise.all([
      api.getInvoices(),
      api.getInvoices({
        per_page: -1,
        filter: JSON.stringify([
          {
            field: 'deadline',
            operator: 'lt',
            value: new Date().toISOString().split('T')[0],
          },
          {
            field: 'paid',
            operator: 'eq',
            value: false,
          },
        ]),
      }),
    ])
    setInvoicesList(invoicesResponse.data.invoices)
    setOverdueInvoicesList(overdueResponse.data.invoices)
    setIsFetching(false)
  }, [api])

  useEffect(() => {
    fetchInvoices()
  }, [fetchInvoices])

  if (isFetching) {
    return <Container>Loading invoices...</Container>
  }

  return (
    <Container fluid className="h-100 p-4 vh-100">
      <div className="h-100 overflow-y-hidden d-flex flex-column">
        <InvoicesHeader
          title="Invoices"
          actionButtonText="New Invoice"
          onActionClick={() => navigate('/invoices/new')}
        />
        {!isOverdueFilterActive ? (
          <OverdueInvoicesAlert overdueCount={overdueInvoicesList.length} />
        ) : (
          <div className="d-flex align-items-center justify-content-between mb-3">
            <Button
              onClick={() => navigate('/invoices')}
              variant="outline-secondary"
              className="me-2"
            >
              Show all invoices
            </Button>
            <Button
              onClick={() => bulkMarkInvoicesAsPaid(overdueInvoicesList)}
              variant="success"
            >
              Mark All as Paid
            </Button>
          </div>
        )}
        <div className="flex-1 overflow-y-auto">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Customer</th>
                <th>Total</th>
                <th>Status</th>
                <th>Invoice Date</th>
                <th>Deadline</th>
              </tr>
            </thead>
            <tbody>
              {invoicesToDisplay.map((invoice) => (
                <tr
                  onClick={() => navigate(`/invoices/${invoice.id}`)}
                  key={invoice.id}
                >
                  <td>{invoice.id}</td>
                  <td>
                    {invoice.customer?.first_name} {invoice.customer?.last_name}
                  </td>
                  <td>{invoice.total}</td>
                  <td>{getInvoiceStatus(invoice)}</td>
                  <td>{invoice.date}</td>
                  <td>{invoice.deadline}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  )
}

export default InvoicesListPage
