import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { useApi } from 'api'
import { InvoicesHeader } from 'app/components/InvoicesHeader'
import { useInvoiceOperations } from 'app/hooks/use-invoice-operations'
import { AddProductOverlay } from 'app/pages/invoices-details/components/AddProductOverlay'
import { CustomerInfoCard } from 'app/pages/invoices-details/components/CustomerInfoCard'
import InvoiceLines from 'app/pages/invoices-details/components/InvoiceLines'
import { InvoiceStatus } from 'app/pages/invoices-details/components/InvoiceStatus'
import { NewInvoice } from 'app/pages/invoices-details/components/NewInvoice'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Invoice, Product } from 'types'

const InvoicesDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const api = useApi()
  const navigate = useNavigate()
  const {
    addProduct,
    finalizeInvoice,
    markInvoiceAsPaid,
    deleteInvoice,
    deleteInvoiceLine,
  } = useInvoiceOperations()

  const [invoice, setInvoice] = useState<Invoice>()
  const [isProductSelectionVisible, setIsProductSelectionVisible] =
    useState(false)
  const [shouldRefetch, setShouldRefetch] = useState(false)

  const closeProductSelectionOverlay = useCallback(() => {
    setIsProductSelectionVisible(false)
    setShouldRefetch((prev) => !prev)
  }, [])
  const showProductSelectionOverlay = () => setIsProductSelectionVisible(true)

  const isNewInvoice = id === 'new'

  useEffect(() => {
    if (isNewInvoice) {
      return
    }
    api.getInvoice(id).then(({ data }) => {
      setInvoice(data)
    })
  }, [api, id, isNewInvoice, shouldRefetch])

  const isFinalized = invoice?.finalized
  const isPaid = invoice?.paid
  const canEditInvoiceLines = Boolean(invoice && !isFinalized)
  const hasInvoiceLines =
    invoice?.invoice_lines && invoice.invoice_lines.length > 0

  const handleAddProduct = async (product: Product, quantity: number) => {
    if (!invoice) return
    const success = await addProduct(invoice, product, quantity)
    if (success) closeProductSelectionOverlay()
  }

  const handleFinalizeInvoice = async () => {
    if (!invoice) return
    const success = await finalizeInvoice(invoice)
    if (success) setShouldRefetch((prev) => !prev)
  }

  const handleMarkInvoiceAsPaid = async () => {
    if (!invoice) return
    const success = await markInvoiceAsPaid(invoice)
    if (success) setShouldRefetch((prev) => !prev)
  }

  const handleDeleteInvoice = async () => {
    if (!invoice) return
    await deleteInvoice(invoice)
  }

  const handleDeleteInvoiceLine = async (lineId: number) => {
    if (!invoice) return
    const success = await deleteInvoiceLine(invoice, lineId)
    if (success) setShouldRefetch((prev) => !prev)
  }

  if (isNewInvoice) {
    return <NewInvoice />
  }

  if (!invoice) {
    return <Container>Loading...</Container>
  }

  return (
    <Container className="h-100 p-4">
      <InvoicesHeader
        title="Invoice Editor"
        actionButtonText="Back to invoices list"
        onActionClick={() => navigate('/invoices')}
      />
      <Row className="align-items-center mb-4">
        <Col md="auto">
          <h1 className="m-0">Invoice #{id}</h1>
        </Col>
        <Col>
          <InvoiceStatus invoice={invoice} />
        </Col>
      </Row>
      {canEditInvoiceLines && (
        <Row className="my-4 align-items-center">
          {hasInvoiceLines && (
            <Col md="auto">
              <Button
                className="btn btn-primary"
                onClick={handleFinalizeInvoice}
              >
                Finalize Invoice
              </Button>
            </Col>
          )}
          <Col>
            <Button className="btn btn-danger" onClick={handleDeleteInvoice}>
              Delete Invoice
            </Button>
          </Col>
        </Row>
      )}
      {isFinalized && !isPaid && (
        <Row className="my-4 align-items-center">
          <Col>
            <Button
              className="btn btn-danger"
              onClick={handleMarkInvoiceAsPaid}
            >
              Mark as Paid
            </Button>
          </Col>
        </Row>
      )}
      <CustomerInfoCard invoice={invoice} />
      <InvoiceLines
        invoice={invoice}
        onDeleteLine={handleDeleteInvoiceLine}
        canEditInvoiceLines={canEditInvoiceLines}
        onAddLine={showProductSelectionOverlay}
      />
      <pre>{JSON.stringify(invoice ?? '', null, 2)}</pre>
      <AddProductOverlay
        show={isProductSelectionVisible}
        handleClose={closeProductSelectionOverlay}
        onAddProduct={handleAddProduct}
      />
    </Container>
  )
}

export default InvoicesDetailsPage
