import { useApi } from 'api'
import { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Customer } from 'types'
import CustomerAutocomplete from '../../../components/CustomerAutocomplete'
import { InvoicesHeader } from '../../../components/InvoicesHeader'

export const NewInvoice = () => {
  const [customer, setCustomer] = useState<Customer | null>(null)

  const navigate = useNavigate()
  const api = useApi()

  const createInvoice = async () => {
    if (!customer) {
      return
    }
    const returnValue = await api.postInvoices(
      {},
      { invoice: { customer_id: customer.id } }
    )
    console.log(returnValue.data)
    if (returnValue.status === 200) {
      navigate(`/invoices/${returnValue.data.id}`)
    }
  }

  return (
    <Container className="h-100 p-4">
      <InvoicesHeader
        title="New Invoice"
        actionButtonText="Back to invoices list"
        onActionClick={() => navigate('/invoices')}
      />
      <CustomerAutocomplete value={customer} onChange={setCustomer} />
      <Button className="mt-4" disabled={!customer} onClick={createInvoice}>
        Create New Invoice
      </Button>
    </Container>
  )
}
