import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Container, Navbar } from 'react-bootstrap'

interface InvoicesHeaderProps {
  title: string
  onActionClick: () => void
  actionButtonText: string
}

export const InvoicesHeader = ({
  title,
  onActionClick,
  actionButtonText,
}: InvoicesHeaderProps) => {
  return (
    <Navbar bg="primary" variant="dark" className="mb-4">
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Navbar.Brand className="fs-4">{title}</Navbar.Brand>

          <Button variant="outline-light" onClick={onActionClick}>
            {actionButtonText}
          </Button>
        </div>
      </Container>
    </Navbar>
  )
}
