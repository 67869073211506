import { BadgeWithTooltip } from 'app/components/BadgeWithTooltip'
import React from 'react'
import { Invoice } from 'types'

interface InvoiceStatusProps {
  invoice: Invoice
}

export const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ invoice }) => {
  const isFinalized = invoice?.finalized
  const isOverdue =
    invoice?.deadline &&
    new Date(invoice.deadline) < new Date() &&
    !invoice.paid &&
    isFinalized

  return (
    <div className="d-flex gap-2">
      {isOverdue && (
        <BadgeWithTooltip bg="danger" tooltipText="This invoice is overdue">
          Overdue
        </BadgeWithTooltip>
      )}
      <BadgeWithTooltip
        tooltipText={
          isFinalized
            ? 'The invoice is finalized and locked'
            : 'In Draft you can edit the invoice'
        }
      >
        {isFinalized ? 'Finalized' : 'In Draft'}
      </BadgeWithTooltip>
    </div>
  )
}
